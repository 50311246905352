import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      You've landed on
    </div>
  )
}

export default ErrorPage;
